import { PayKitForm } from '@paykassma/pay-kit';
import { BalanceOperations as BalanceOperationsType } from 'api/reportsGroup';
import { AccountsBalancesContext } from 'contexts/AccountsBalanceContext';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import { useContext, useEffect } from 'react';
import { fromLocaleDate, notLaterThanToday } from 'utils/date';
import { WalletTypes } from 'utils/enums';

import { ActionsContext } from '../../../../../ActionsProvider';

import styles from './BalanceOperations.module.scss';

type FormState = {
	readonly wallet_type: string;
	readonly currency_code: string;
	readonly direction?: 'outgoing' | 'ingoing';
	readonly transaction_type?: 1 | 2;
	readonly counterparty_wallet_type?: string;
	readonly external_address?: string;
	readonly ex_account?: string;
	readonly period: Readonly<{
		readonly from: string;
		readonly to: string;
	}>;
	readonly file_format: 'xlsx' | 'csv';
};

type prepareFormDataType = (rawFormData: FormState) => BalanceOperationsType;

const prepareFormData: prepareFormDataType = (rawFormData) => ({
	wallet_type: rawFormData.wallet_type,
	currency_code: rawFormData.currency_code,
	direction: rawFormData.direction,
	transaction_type: rawFormData.transaction_type,
	counterparty_wallet_type: rawFormData.counterparty_wallet_type,
	external_address: rawFormData.external_address,
	file_format: rawFormData.file_format,
	date_from: fromLocaleDate(rawFormData.period.from) || undefined,
	date_to: fromLocaleDate(rawFormData.period.to) || undefined,
});

export const BalanceOperations = () => {
	const { t } = useTranslation();
	const walletsContext = useContext(WalletTypesContext);
	const { getAccountsBalanceHistory, isLoading, accountsBalances } = useContext(
		AccountsBalancesContext,
	);
	const { createBalanceOperationsReportAPI } = useContext(ActionsContext);

	useEffect(() => {
		getAccountsBalanceHistory();
	}, []);

	const walletTypesOptions = [
		...walletsContext.walletTypes.map(({ name, code }) => ({
			label: name,
			value: code,
		})),
	].filter((el) => el.label !== t('All'));

	const getCurrenciesOfCurrentWalletType = (wallet_type: undefined | string) =>
		accountsBalances
			.find(({ payment_system }) => wallet_type === payment_system)
			?.balances.map(({ currency_code }) => ({
				label: currency_code,
				value: currency_code,
			})) || [];

	const allCurrencies = walletsContext.walletTypes
		.map((c) => c.supported_currencies)
		.reduce((accum, current) => [...new Set([...accum, ...current])], []);

	const allCurrenciesOptions = allCurrencies.map((c) => ({
		label: c,
		value: c,
	}));

	const schema = [
		{
			type: 'BaseSettingsSection',
			elements: [
				{
					name: 'wallet_type',
					label: t('Wallet type'),
					type: 'Select',
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
					isRequired: true,
					validation: (value: string, { wallet_type }: FormState) =>
						!wallet_type ? t('Choose wallet type') : undefined,
				},
				{
					name: 'currency_code',
					label: t('Currency'),
					placeholder: '',
					type: 'Select',
					options: ({ wallet_type }: FormState) =>
						wallet_type
							? getCurrenciesOfCurrentWalletType(wallet_type)
							: allCurrenciesOptions,
					isLoading: isLoading,
					isRequired: true,
					validation: (value: string, { currency_code }: FormState) =>
						currency_code === undefined ? t('Choose currency') : undefined,
				},
				{
					name: 'direction',
					label: t('Direction'),
					type: 'Select',
					options: [
						{ label: t('All') },
						{ value: 'outgoing', label: t('Outgoing') },
						{ value: 'ingoing', label: t('Ingoing') },
					],
				},
				{
					name: 'transaction_type',
					label: t('Operation type'),
					type: 'Select',
					existsIf: (form) => form.wallet_type === WalletTypes.PAYKASSMA,
					options: [
						{ label: t('Account balance'), value: 1 },
						{ label: t('External account'), value: 2 },
					],
				},
				{
					name: 'period',
					label: t('Period'),
					type: 'DateRangePicker',
					fromPlaceholder: t('From'),
					toPlaceholder: t('To'),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					isRequired: true,
					validation: (value: FormState['period']) => {
						const { from = false, to = false } = value ?? {};

						if (!from || !to) {
							return t('Choose period');
						}
					},
					customStyles: () => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -100px)`,
					}),
				},
				{
					name: 'external_address',
					label: t('External account'),
					type: 'TextInput',
					existsIf: (form) => form?.transaction_type === 2,
				},
				{
					name: 'counterparty_wallet_type',
					label: t('Counterparty wallet type'),
					type: 'Select',
					options: walletTypesOptions,
					existsIf: (form) =>
						form.wallet_type !== WalletTypes.PAYKASSMA ||
						form?.transaction_type === 1,
				},
			],
		},
		{
			type: 'BottomSection',
			elements: [
				{
					name: 'file_format',
					label: t('Report format'),
					type: 'Switcher',
					isRequired: true,
					options: [
						{ value: 'xlsx', label: 'xlsx' },
						{ value: 'csv', label: 'csv' },
					],
					className: styles.formatSwitcher,
				},
				{
					type: 'SubmitButton',
					name: 'submitButton',
					label: t('Create'),
					isLoading: createBalanceOperationsReportAPI.isLoading,
					onSubmit: (formState: FormState) => {
						createBalanceOperationsReportAPI.create(prepareFormData(formState));
					},
				},
			],
		},
	];

	const initialState: Partial<FormState> = {
		file_format: 'xlsx',
		transaction_type: 1,
	};

	const customElements = {
		BaseSettingsSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.baseSettings}>{children}</div>
				)}
			/>
		),
		BottomSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => <div className={styles.actions}>{children}</div>}
			/>
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder
				schema={schema}
				customElements={customElements}
				initialState={initialState}
			/>
		</div>
	);
};
