import { useTranslation } from '@paykassma/pay-kit';
import api, { CompletedSumReportReqType } from 'api/reportsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

export type CreateTransactionsCompletedSumReportType = (args?: {
	readonly onSuccess?: (data: any) => void;
	readonly onError?: (err: Error) => void;
}) => {
	readonly create: (data: CompletedSumReportReqType, detail: boolean) => void;
	readonly isLoading: boolean;
};

const CreateTransactionsCompletedSumReport: CreateTransactionsCompletedSumReportType =
	(props) => {
		const [isLoading, setLoading] = useState<boolean>(false);
		const { t } = useTranslation();

		const create = (data: CompletedSumReportReqType, detail: boolean) => {
			setLoading(true);

			api
				.createTransactionsCompletedSumReport(data, detail)
				.then((res: any) => {
					if (res?.status === 'success' || res?.status === 'ok') {
						props?.onSuccess?.(res);

						window.pushAlert({
							title: t('The report has been sent to the queue for processing'),
							type: 'success',
						});
					} else {
						// throw new Error("CreateTransactionsCompletedSumReport – Unexpected response");
						throw new Error(errorsMap.anyResponse);
					}

					return res;
				})
				.finally(() => setLoading(false))
				.catch((err) => {
					props?.onError?.(err);
					console.error(err);

					window.pushAlert({
						title: t('An error occurred while creating report'),
						type: 'error',
					});
				});
		};

		return {
			create,
			isLoading,
		};
	};

export default CreateTransactionsCompletedSumReport;
