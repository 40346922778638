import API from 'api';
import { GetChangeLogUsersType } from 'api/changeLogGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useChangeLogUsers = () => {
	const [list, setList] = useState<GetChangeLogUsersType['data']>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load = () => {
		setIsLoading(true);
		setError(null);

		API.changeLog
			.getChangeLogUsers()
			.then((res) => {
				if (res.status === 'success') {
					setList(res.data);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	};

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useChangeLogUsers;
