import { ChangeLogType } from 'api/changeLogGroup';
import DateTime from 'components/DateTime';
import Spoiler from 'modules/ChangeLog/LogInfoViewer';
import { t } from 'pay-kit';

import styles from './changeLogsColumns.module.scss';

const User = {
	title: t('User'),
	dataIndex: 'user',
	key: 'user',
	dataTestId: 'user',
};

const Date = {
	title: t('Date'),
	dataIndex: 'created_at',
	key: 'created_at',
	render: ({ created_at }: ChangeLogType) => (
		<DateTime dateTime={created_at} dataTestId='created_at' />
	),
};

const ObjectType = {
	title: t('Object type'),
	dataIndex: 'object_type',
	key: 'object_type',
	dataTestId: 'object_type',
};

const ObjectID = {
	title: t('Object ID'),
	dataIndex: 'object_id',
	key: 'object_id',
	dataTestId: 'object_id',
};

const ActionType = {
	title: t('Action type'),
	dataIndex: 'action',
	key: 'action',
	dataTestId: 'action',
};

const OldValue = {
	title: t('Old value'),
	dataIndex: 'old_value',
	key: 'old_value',
	render: ({ old_value }: ChangeLogType) => (
		<Spoiler
			infoList={old_value}
			buttonText={{ showMore: t(`Show all`), showLess: t(`Collapse`) }}
			dataTestId='old_value'
		/>
	),
};

const NewValue = {
	title: t('New value'),
	dataIndex: 'new_value',
	key: 'new_value',
	render: ({ new_value }: ChangeLogType) => {
		return (
			<Spoiler
				// Может попасть пустая строка, которая выглядит как пустое двоеточие.
				infoList={new_value.filter((el) => el !== '')}
				buttonText={{ showMore: t(`Show all`), showLess: t(`Collapse`) }}
				dataTestId='new_value'
			/>
		);
	},
};

export const changeLogColumns = [
	User,
	Date,
	ObjectType,
	ObjectID,
	ActionType,
	OldValue,
	NewValue,
];
