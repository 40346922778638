import { LoadQueryParams } from 'api/walletGroup';
import WalletTypesContextProvider from 'contexts/WalletTypesContext';
import { useEffect } from 'react';
import { fromLocaleDate } from 'utils/date';
import useURLFilters from 'utils/hooks/useURLFilters';

import ActionsProvider from './components/ActionsProvider';
import EmptyPlaceholder from './components/EmptyPlaceholder';
import Filter from './components/WalletsLoadFilter';
import WalletsLoadTable from './components/WalletsLoadTable';
import useWalletsLoadListAPI from './hooks/useWalletsLoadListAPI';
import styles from './WalletsLoad.module.scss';

export const FILTER_DEFAULT_STATE: LoadQueryParams = {
	direction: 'outgoing',
	load_object: 'wallets',
	load_type: 'transactions_sum',
};

export default function WalletsLoad() {
	const [filterState, setFilterState] = useURLFilters({
		initialState: FILTER_DEFAULT_STATE,
	});
	const walletsLoadListAPI = useWalletsLoadListAPI();

	const submitHandler = (value: LoadQueryParams) => {
		const data = { ...value };
		const dateParts = [`create_date_from`, `create_date_to`];

		for (const dataKey of dateParts) {
			if (data[dataKey]) {
				data[dataKey] = fromLocaleDate(data[dataKey]);
			}
		}

		for (const [key, val] of Object.entries(value)) {
			if (val === undefined) {
				delete data[key];
			}
		}

		setFilterState(data);
		walletsLoadListAPI.getList(data);
	};

	useEffect(() => {
		walletsLoadListAPI.getList(filterState);
	}, []);

	return (
		<ActionsProvider>
			<WalletTypesContextProvider>
				<div className={styles.walletsLoad}>
					<div className={styles.content}>
						{walletsLoadListAPI.list === null && <EmptyPlaceholder />}
						{walletsLoadListAPI.list !== null && (
							<WalletsLoadTable
								data={walletsLoadListAPI.list}
								filterState={filterState}
								isLoading={walletsLoadListAPI.isLoading}
							/>
						)}
					</div>
					<div className={styles.controls}>
						<Filter
							filterInitState={filterState}
							onSubmit={submitHandler}
							data-test-id='WalletsLoadFilter'
						/>
					</div>
				</div>
			</WalletTypesContextProvider>
		</ActionsProvider>
	);
}
