import { IWithdrawalsFilterForm, WithdrawalType } from 'api/withdrawalGroup';
import Filter from 'components/Filter';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import React, { useContext } from 'react';
import { rejectSettlement } from 'utils/filterSettlement';

import { StatusesContext } from '../../contexts/statusesContext';

import styles from './WithdrawalsFilter.module.scss';

interface IFilterProps {
	readonly value: IWithdrawalsFilterForm;
	readonly onApply: (value: IWithdrawalsFilterForm) => void;
}

const WithdrawalsFilter: React.FC<IFilterProps> = ({ value, onApply }) => {
	const { t } = useTranslation();
	const { walletTypes } = useContext(WalletTypesContext);
	const statusesContext = useContext(StatusesContext);

	return (
		<Filter
			values={value}
			onApply={onApply}
			classNames={{ wrapper: styles.filter, inlineFilter: styles.inlineFilter }}
			maxInlineFields={5}
			fields={[
				{
					name: 'wallet_type',
					placeholder: t('Wallet type'),
					component: 'select',
					options: rejectSettlement(walletTypes).map((type) => ({
						value: type.code,
						label: type.name,
					})),
				},
				{
					name: 'status',
					placeholder: t('Status'),
					component: 'select',
					isLoading: statusesContext.isLoading,
					options: [
						{ value: null, label: t('All') },
						...statusesContext.list.map((s) => ({
							value: s.status as any,
							label: s.name,
						})),
					],
				},
				{
					name: 'postback_status',
					placeholder: t('Postback status'), // "Постбeк",
					component: 'select',
					options: [
						{ value: '1', label: t('Received') },
						{ value: '0', label: t('Error') },
					],
				},
				{
					name: 'label',
					placeholder: t('Label'),
					component: 'textInput',
				},
				{
					name: 'date',
					label: t('Date'),
					component: 'dateRange',
					fromPlaceholder: t('From'), // "От",
					toPlaceholder: t('To'), // "До",
					dateTimeFormat: 'DD.MM.YYYY',
					blockPredicate: (date) => date.getTime() >= new Date().getTime(),
					withTime: true,
					customStyles: () => ({
						right: 'unset',
						transform: 'translateX(-200px)',
					}),
				},
				{
					name: 'wallet_sender',
					placeholder: t('Sender'),
					component: 'textInput',
				},
				{
					name: 'wallet_sender_identifier',
					placeholder: t('Sender identifier'),
					component: 'textInput',
				},
				{
					name: 'wallet_recipient',
					placeholder: t('Recipient'),
					component: 'textInput',
				},
				{
					name: 'withdrawal_id',
					// placeholder: "ID Вывода",
					placeholder: t('Withdrawal ID'),
					component: 'textInput',
				},
				{
					name: 'is_manually',
					placeholder: t('Withdrawal method'),
					component: 'select',
					options: [
						{ value: '0', label: 'API' },
						{ value: '1', label: t('Manually from PA') },
					],
				},
				{
					name: 'type',
					placeholder: t('Request type'),
					component: 'select',
					options: [
						{ value: `${WithdrawalType.AUTO}`, label: t('Auto') }, //"Авто" },
						{ value: `${WithdrawalType.MANUAL}`, label: t('Manual') }, // "Ручная" },
					],
				},
			]}
		/>
	);
};

export default WithdrawalsFilter;
