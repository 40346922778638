import '@paykassma/pay-kit/lib/paykit.css';
import axios from 'axios';
import AccountsBalancesContextProvider from 'contexts/AccountsBalanceContext';
import AlertsProvider from 'contexts/AlertsProvider';
import AuthContextProvider, {
	AuthContext,
} from 'contexts/AuthContext/AuthContext';
import CurrenciesContextProvider from 'contexts/CurrenciesContext';
import WalletTypesContextProvider from 'contexts/WalletTypesContext';
import { Loader, TranslationProvider } from 'pay-kit';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { errorsMap } from 'utils/enums';

import Router from './Router';

type AppState = 'init' | 'loading' | 'error' | 'default';

type MainAppProps = {
	state: AppState;
};

const Main = () => {
	const { isExchanging } = useContext(AuthContext);

	return (
		<TranslationProvider useDefaultLangOnInit isExchanging={isExchanging}>
			<WalletTypesContextProvider>
				<CurrenciesContextProvider>
					<AccountsBalancesContextProvider>
						<Router />
					</AccountsBalancesContextProvider>
				</CurrenciesContextProvider>
			</WalletTypesContextProvider>
		</TranslationProvider>
	);
};

const AuthApp = ({ state }: MainAppProps) => {
	switch (state) {
		case 'init':
		case 'loading':
			return (
				<>
					loading <Loader />
				</>
			);
		case 'error':
			return <>{errorsMap.any}</>;
		case 'default':
			return (
				<AuthContextProvider>
					<Main />
				</AuthContextProvider>
			);
		default:
			return null;
	}
};

const App = () => {
	const [state, setState] = useState<AppState>('init');

	const envUrl = '/env.json';

	useEffect(() => {
		setState('loading');

		axios
			.get(envUrl)
			.then((resp) => {
				window.config = resp.data;
				setState('default');

				return resp;
			})
			.catch((e) => {
				console.error(e);
				setState('error');
			});
	}, []);

	return (
		<AlertsProvider>
			<BrowserRouter>
				<AuthApp state={state} />
			</BrowserRouter>
		</AlertsProvider>
	);
};

export default App;
