import { ICreateNewWalletType } from 'api/walletTypesGroup';
import { t } from 'pay-kit';
import {
	AnyObjectSchema,
	ValidationError,
	array,
	mixed,
	number,
	object,
	string,
} from 'yup';

const isValidFileList = (filelist: FileList, context: any) => {
	if (!filelist) {
		return context?.createError();
	}

	return true;
};

export const step1ValidationSchema = object().shape({
	logo: mixed().test(
		'required-file-list',
		t('Select file'),
		isValidFileList as any,
	),
	name: string().required(t('Required field')),
	code: string()
		.required(t('Required field'))
		.matches(/^\w+$/, {
			message: t('Invalid characters'),
			name: 'only-en-letters-and-numbers-and-underscore',
			excludeEmptyString: true,
		})
		.test(
			'ends-with-_ml',
			t('The name must end with _ml'),
			(v) => v?.endsWith('_ml') === true,
		),
	currency: string().required(t('Required field')),
	limits: object().shape({
		deposit: object().shape({
			min: number()
				.required(t('Required field'))
				.typeError(t('Enter number'))
				.positive(t('Positive numbers only'))
				.test(
					'not-bigger-than-max',
					t('Min limit must be less than max'),
					(min, context) => {
						const max = context.parent.max;

						if (max !== undefined && min !== undefined) {
							return min < max;
						}

						return true;
					},
				),
			max: number()
				.typeError(t('Enter number'))
				.required(t('Required field'))
				.positive(t('Positive numbers only'))
				.test(
					'not-smaller-than-min',
					t('Min limit must be less than max'),
					(max, context) => {
						const min = context.parent.min;

						if (max !== undefined && min !== undefined) {
							return min < max;
						}

						return true;
					},
				),
		}),
	}),
	requisites: array().of(
		object({
			code: string()
				.required(t('Required field'))
				.matches(/^[a-zA-Z\d., ]*$/, {
					message: t('Name of requisite in English'),
					name: 'english-sentences',
					excludeEmptyString: true,
				}),
		}),
	),
	show_transaction_id: number(),
	validation_transaction_id_from: number()
		.required(t('Required field'))
		.typeError(t('Enter number'))
		.positive(t('Positive numbers only'))
		.test(
			'not-longer-than-to',
			t('Min limit must be less than max'),
			(from, context) => {
				const to = context.parent.validation_transaction_id_to;

				if (from !== undefined && to !== undefined) {
					return to > from;
				}

				return true;
			},
		),
	validation_transaction_id_to: number()
		.required(t('Required field'))
		.typeError(t('Enter number'))
		.positive(t('Positive numbers only'))
		.test(
			'not-shorter-than-from',
			t('Min limit must be less than max'),
			(to, context) => {
				const from = context.parent.validation_transaction_id_from;

				if (from !== undefined && to !== undefined) {
					return to > from;
				}

				return true;
			},
		),
	show_payment_bill: number().required(t('Required field')),
});

export const step2ValidationSchema = object().shape({
	hints: array().of(
		object().shape({
			lang: string().required(t('Required field')),
			text: string().required(t('Required field')),
			form_type: string().required(t('Required field')),
		}),
	),
	animation_hint: mixed().test(
		'required-file-list',
		t('Select file'),
		isValidFileList as any,
	),
	screenshot: mixed().test(
		'required-file-list',
		t('select file'),
		isValidFileList as any,
	),
});

export const validateForm = async (
	formState: ICreateNewWalletType,
	formSchema: AnyObjectSchema,
) => {
	return new Promise((resolve) =>
		formSchema
			.validate(formState, { abortEarly: false })
			.then(() => resolve(null))
			.catch((err) => {
				const errors: { [key: string]: string } = {};

				err.inner.forEach((e: ValidationError) => {
					if (e.path) {
						errors[e.path] = e.message;
					}
				});

				resolve(errors);
			}),
	);
};

type getErrorsForTouchedFieldsType = (
	errors: { readonly [key: string]: string } | null,
	touchedFields: readonly string[] | '*',
) => { readonly [key: string]: string };

export const getErrorsForTouchedFields: getErrorsForTouchedFieldsType = (
	errors,
	touchedFields,
) => {
	if (errors === null) {
		return {};
	}

	if (touchedFields === '*') {
		return errors;
	}

	const visibleErrors: { [key: string]: string } = {};

	Object.keys(errors).forEach((fieldName) => {
		if (touchedFields.includes(fieldName)) {
			visibleErrors[fieldName] = errors[fieldName];
		}
	});

	return visibleErrors;
};
