import { fetchApi, fetchOldApi } from 'api/fetch';

const translationsGroup = {
	getLanguages: () => fetchOldApi('/translation/language', {}, 'GET'),
	getFormTypes: () => fetchOldApi('/translation/form-type', undefined, 'GET'),
	getHints: (wallet_type: string, isDefault: boolean) =>
		fetchOldApi(
			`/translation/hint?wallet_type=${wallet_type}${isDefault ? '&is_default=true' : ''}`,
			undefined,
			'GET',
		),
	getPackages: (wallet_type: string, isDefault: boolean) =>
		fetchOldApi(
			`/translation/package?wallet_type=${wallet_type}${isDefault ? '&is_default=true' : ''}`,
			undefined,
			'GET',
		),
	createNewPackages: (data: CreateNewTranslationPackagesDataType) =>
		fetchOldApi(`/translation/package`, data, 'POST'),
	updatePackages: (packageId: string, data: UpdateTranslationPackageDataType) =>
		fetchApi(`/translation/package/${packageId}`, data, 'PUT'),
	removePackages: (packageId: string) =>
		fetchOldApi(`/translation/package/${packageId}`, undefined, 'DELETE'),
	createNewHint: (data: CreateNewHintDataType) =>
		fetchOldApi(`/translation/hint`, data, 'POST'),
	updateHint: (uuid: string, data: UpdateTranslationHintDataType) =>
		fetchApi(`/translation/hint/${uuid}`, data, 'PUT'),
	deleteHint: (uuid: string) =>
		fetchOldApi(`/translation/hint/${uuid}`, undefined, 'DELETE'),
};

export type TranslationHintType = {
	readonly uuid: string;
	readonly wallet_type: string;
	readonly wallet_type_uuid: string;
	readonly form_type: string;
	readonly name: string;
	readonly is_default: true;
	readonly is_manual: false;
	readonly created_at: string;
	readonly updated_at: string;
	readonly translations: readonly TranslationItemType[];
};

export type CreateNewHintDataType = Pick<
	TranslationHintType,
	'form_type' | 'is_manual' | 'name' | 'translations' | 'wallet_type'
>;

export type UpdateTranslationHintDataType = {
	readonly is_manual: boolean;
	readonly name: string;
	readonly translations: readonly TranslationItemType[];
};

export type PackageType = {
	readonly field: string;
	readonly name: string;
	readonly code: string;
	readonly translations: readonly TranslationItemType[];
};

export type TranslationPackageType = {
	readonly uuid: string;
	readonly wallet_type: string;
	readonly wallet_type_uuid: string;
	readonly form_type: string;
	readonly name: string;
	readonly is_default: boolean;
	readonly is_manual: boolean;
	readonly created_at: string;
	readonly updated_at: string;
	readonly packages_translations: readonly PackageType[];
};

export type CreateNewTranslationPackagesDataType = Pick<
	TranslationPackageType,
	'form_type' | 'is_manual' | 'name' | 'wallet_type'
> & {
	readonly packages_translations: readonly Pick<
		PackageType,
		'field_uuid' | 'translations'
	>[];
};

export type UpdateTranslationPackageDataType = {
	readonly name: string;
	readonly is_manual: boolean;
	readonly packages_translations: readonly {
		readonly field_uuid: string;
		readonly translations: readonly TranslationItemType[];
	}[];
};

export type TranslationItemType = {
	readonly language: string;
	readonly text: string;
};

export type FormTypesItemType = {
	readonly title: string;
	readonly type: string;
};

export default translationsGroup;
