import { PSBalanceType } from 'api/balanceGroup';
import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';
import { convertIntoCurrency } from 'helpers/convertIntoCurrency';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import { useTranslation } from 'pay-kit';
import { Button } from 'pay-kit';
import { FC, useContext } from 'react';
import { useParams } from 'react-router-dom';

import styles from './availableBalance.module.scss';

type AvailableBalanceType = {
	readonly PSBalance: PSBalanceType | undefined;
};

const AvailableBalance: FC<AvailableBalanceType> = ({ PSBalance }) => {
	const { t } = useTranslation();
	const { showReplenishModal, openWithdrawModal, showTransferringModal } =
		useOperationHistoryContext();
	const { hasRole } = useContext(AuthContext);
	const { ps } = useParams();
	const hasManagePermission = hasRole(Roles.MANAGER_BALANCE);
	const hasSettlementCreateWithdrawalPermission = hasRole(
		Roles.SETTLEMENT_CREATE_WITHDRAWAL,
	);

	const handleReplenishOnclick = () =>
		showReplenishModal((prev: boolean) => !prev);
	const handleWithdrawOnclick = () =>
		openWithdrawModal((prev: boolean) => !prev);
	const handleTransferOnclick = () =>
		showTransferringModal((prev: boolean) => !prev);

	const amount = convertIntoCurrency({
		amount: PSBalance?.amount || 0,
		minimumFractionDigits: 4,
		maximumFractionDigits: 4,
	});
	const isSettlement = ps === 'paykassma';

	return (
		<div className={styles.accountBalanceWrapper}>
			<div className={styles.availableBalance}>{t('Available balance')}:</div>
			<div className={styles.amountContainer}>
				<div className={styles.amount}>
					{amount}
					<span className={styles.currencyCode}>
						{PSBalance?.currency_code}
					</span>
				</div>
				{isSettlement && (
					<>
						{hasManagePermission && PSBalance?.is_settlement && (
							<Button
								data-test-id='ExMbsN_TIL1qQW799uX9b'
								onClick={handleReplenishOnclick}
								classname={styles.replenishButton}
							>
								{t('Replenish')}
							</Button>
						)}

						{hasSettlementCreateWithdrawalPermission &&
							PSBalance?.is_settlement && (
								<Button
									data-test-id='ExMbsN_TIL1qQW799uX9b'
									onClick={handleWithdrawOnclick}
									classname={styles.withdrawButton}
								>
									{t('Withdrawal')}
								</Button>
							)}
					</>
				)}
				{hasManagePermission && (
					<Button
						data-test-id='ExMbsN_TIL1qQW799uX9b'
						onClick={handleTransferOnclick}
						classname={styles.transferButton}
					>
						{t('Transferring')}
					</Button>
				)}
			</div>
		</div>
	);
};

export default AvailableBalance;
