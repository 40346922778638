import { PayKitForm } from '@paykassma/pay-kit';
import CustomWalletOption from 'components/CustomWalletOption';
import { WalletType } from 'api/walletGroup';
import AuthContext from 'contexts/AuthContext';
import { Roles } from 'contexts/AuthContext/utils/enums';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import { useContext, useEffect, useState } from 'react';
import { fromLocaleDate, notLaterThanToday } from 'utils/date';
import { rejectSettlement } from 'utils/filterSettlement';

import { SumReportReqData } from '/api/reportsGroup';

import { ActionsContext } from '../../../../../ActionsProvider';
import useGetAllKindWallets from '../../../../hooks/getAllKindWallets';

import styles from './TransactionsSum.module.scss';

type prepareFormDataType = (rawFormData: FormStateType) => SumReportReqData;

const prepareFormData: prepareFormDataType = (rawFormData) => ({
	report_currency_code: rawFormData.report_currency_code as string,
	file_format: rawFormData.file_format,
	filters: {
		originality: rawFormData.originality,
		wallet_type: rawFormData.wallet_type,
		direction: rawFormData.direction,
		exchanger_identifier: rawFormData.exchanger_identifier,
		label: rawFormData.label,
		creation_type: rawFormData.creation_type as any,
		status: rawFormData.status,
		type: rawFormData.type as any,
		created_from: fromLocaleDate(rawFormData.created?.from),
		created_to: fromLocaleDate(rawFormData.created?.to),
		activated_from: fromLocaleDate(rawFormData.activated?.from),
		activated_to: fromLocaleDate(rawFormData.activated?.to),
		wallet_hash_id: rawFormData.wallet_hash_id,
	},
	fields: rawFormData.commission
		? ['total_commission', 'deposit_commission', 'withdrawal_commission']
		: undefined,
	utc_0: rawFormData.utc_0,
});

const TransactionsSum = () => {
	const walletsContext = useContext(WalletTypesContext);
	const { getWalletsAPI, createTransactionsSumReportAPI } =
		useContext(ActionsContext);
	const allKindWallets = useGetAllKindWallets();

	const { t } = useTranslation();

	const [currentWalletType, setWalletType] = useState<WalletType | null>(null);
	const { hasRole } = useContext(AuthContext);
	const hasWalletsAccess = hasRole(Roles.WALLET_LIST);

	useEffect(() => {
		if (currentWalletType && hasWalletsAccess) {
			allKindWallets.load(currentWalletType);
		}
	}, [currentWalletType, hasWalletsAccess]);

	// wallet types
	const walletTypesOptions = [
		{ label: t('All') },
		...rejectSettlement(walletsContext.walletTypes).map(({ name, code }) => ({
			label: name,
			value: code,
		})),
	];

	// wallets (filtering by wallet type)
	const getWalletsOptions = (wallet_type: string) => [
		{ label: t('All') },
		...allKindWallets.list
			.filter((w) => (!wallet_type ? false : w.wallet_type === wallet_type))
			.map((w) => ({
				label: `${w.identifier}`,
				value: w.hash_id,
				is_active: w.is_active,
				removed_at: w.removed_at,
			})),
	];

	// currencies
	const getCurrenciesOfCurrentWalletType = (wallet_type: undefined | string) =>
		walletsContext.walletTypes
			.find(({ code }) => wallet_type === code)
			?.supported_currencies.map((c) => ({ label: c, value: c })) || [];

	const allCurrencies = walletsContext.walletTypes
		.map((c) => c.supported_currencies)
		.reduce((accum, current) => [...new Set([...accum, ...current])], []);

	const allCurrenciesOptions = allCurrencies.map((c) => ({
		label: c,
		value: c,
	}));

	const FORM_SCHEMA = [
		{
			type: 'BaseSettingsSection',
			elements: [
				{
					name: 'wallet_type',
					label: t('Wallet type'),
					type: 'Select',
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
				},
				{
					name: 'direction',
					label: t('Direction'),
					type: 'Select',
					options: [
						{ label: t('All') },
						{ value: 'outgoing', label: t('Outgoing') },
						{ value: 'ingoing', label: t('Ingoing') },
					],
				},
				{
					name: 'wallet_hash_id',
					label: t('Wallet'),
					type: 'Select',
					isLoading: allKindWallets.isLoading,
					options: ({ wallet_type }: FormStateType) =>
						getWalletsOptions(wallet_type as string),
					customOption: CustomWalletOption,
					existsIf: hasWalletsAccess,
					disabled: ({ wallet_type }: FormStateType) => !wallet_type,
				},
				{
					name: 'type',
					label: t('Transaction kind'),
					type: 'MultiSelect',
					placeholder: t('All'),
					options: [
						{ label: t('Confirmed'), value: 0 },
						{ label: t('Forced'), value: 2 },
						{ label: t('Debug'), value: 1 },
					],
				},
				{
					name: 'report_currency_code',
					label: t('Currency'),
					placeholder: '',
					type: 'Select',
					options: ({ wallet_type }: FormStateType) =>
						wallet_type
							? getCurrenciesOfCurrentWalletType(wallet_type)
							: allCurrenciesOptions,
					isLoading: walletsContext.isLoading,
					isRequired: true,
					validation: (
						value: string,
						{ report_currency_code }: FormStateType,
					) =>
						report_currency_code === undefined
							? t('Choose currency')
							: undefined,
				},
				{
					name: 'creation_type',
					label: t('Transaction type'),
					type: 'Select',
					options: [
						{ label: t('All') },
						{ label: t('Auto'), value: 'auto' },
						{ label: t('Manual'), value: 'manual' },
					],
				},
				{
					name: 'created',
					label: t('Period'),
					type: 'DateRangePicker',
					fromPlaceholder: t('From'),
					toPlaceholder: t('To'),
					withTime: true,
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: (date: Date) =>
						date.getTime() >= new Date().getTime(),
					isRequired: ({ activated }: FormStateType) => activated === undefined,
					validation: (
						value: string,
						{ created, activated, status }: FormStateType,
					) => {
						if (!created && !activated) {
							if (status !== 0) {
								return t('Fill at least one period');
							} else {
								return t('Choose period');
							}
						}

						return undefined;
					},
					customStyles: () => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -180px)`,
					}),
				},
				{
					name: 'status',
					label: t('Transaction status'),
					type: 'Select',
					options: [
						{ label: t('All') },
						{ label: t('Activated'), value: 1 },
						{ label: t('Not activated'), value: 0 },
					],
				},
				{
					name: 'activated',
					label: t('Activation period'),
					type: 'DateRangePicker',
					fromPlaceholder: t('From'),
					toPlaceholder: t('To'),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					isRequired: ({ created }: FormStateType) => created === undefined,
					existsIf: ({ status }: FormStateType) => status !== 0,
					validation: (
						value: string,
						{ created, activated, status }: FormStateType,
					) => {
						if (!created && !activated && status !== 0) {
							return t('Fill at least one period');
						}

						return undefined;
					},
					customStyles: () => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -240px)`,
					}),
				},
				{
					name: 'originality',
					label: t('Originality'),
					type: 'Select',
					options: [
						{ label: t('All') },
						{ label: t('Normal'), value: true },
						{ label: t('Scam'), value: false },
					],
				},
				{ name: 'label', label: t('Label'), type: 'TextInput' },
				{ name: 'detail_by_day', label: t('Detail by day'), type: 'Toggler' },
				{
					name: 'exchanger_identifier',
					label: t('Counterparty'),
					type: 'TextInput',
				},
				{ name: 'utc_0', label: t('Timezone UTC+00:00'), type: 'Toggler' },
			],
		},
		{
			type: 'AdditionalSettingsSection',
			elements: [
				{ name: 'commission', label: t('Commission'), type: 'Checkbox' },
			],
		},
		{
			type: 'BottomSection',
			elements: [
				{
					name: 'file_format',
					label: t('Report format'),
					type: 'Switcher',
					options: [
						{ value: 'xlsx', label: 'xlsx' },
						{ value: 'csv', label: 'csv' },
					],
					className: styles.formatSwitcher,
				},
				{
					type: 'SubmitButton',
					name: 'submitButton',
					label: t('Create'),
					isLoading: createTransactionsSumReportAPI.isLoading,
					onSubmit: (formState: FormStateType) => {
						console.log({ formState });

						return createTransactionsSumReportAPI.create(
							prepareFormData(formState),
							formState.detail_by_day === true,
						);
					},
				},
			],
		},
	];

	const customElements = {
		BaseSettingsSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.baseSettings}>{children}</div>
				)}
			/>
		),
		AdditionalSettingsSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.additionalSettings}>
						<h3>{t('Additionally show')}</h3>
						{children}
					</div>
				)}
			/>
		),
		BottomSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => <div className={styles.actions}>{children}</div>}
			/>
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: 'xlsx', utc_0: true }}
				onStateChange={(prevForm, newForm) => {
					if (prevForm.wallet_type !== newForm.wallet_type) {
						setWalletType(newForm.wallet_type);
					}
				}}
				customElements={customElements}
			/>
		</div>
	);
};

export default TransactionsSum;

type FormStateType = {
	readonly originality?: boolean;
	readonly wallet_type?: string; // t("Wallet type")	readonly direction?: "outgoing" | "ingoing"; // Направление
	readonly wallet_id?: string; // t("Wallet")(shows only when wallet type choosen)
	readonly type?: readonly ('0' | '1' | '2')[]; // Вид транзакций
	readonly report_currency_code?: string; // t("Currency")*
	readonly creation_type?: 'auto' | 'manual'; // Тип транзакции
	readonly created?: {
		readonly from: string;
		readonly to: string;
	}; // t("Period")*
	readonly status?: 1 | 0; // 0 :{ text: t("All"), value: null}1:{ text: "Активированные", value: 1}2:{ text: "Неактивированные", value: 0}
	readonly activated?: {
		readonly from: string;
		readonly to: string;
	}; // {t("Activation period")}*
	readonly label?: string; // {t("Label")}	readonly detail_by_day?: boolean; // {t("Detail by day")}(когда true – запрос летит в sum-detail иначе в sum)
	readonly exchanger_identifier?: string; // {t("Counterparty")}	readonly utc_0?: boolean; // {t("Timezone")}UTC+00:00
	// fields: ["total_commission", "deposit_commission", "withdrawal_commission"], // Дополнительно показать -> Комиссия
	readonly commission?: boolean; // галочка комиссии
	readonly file_format: 'xlsx' | 'csv'; // Формат отчета csv | xlsx
};
