export const formatDate = (date: Date) => date.toISOString().split('T')[0];

export const subtractDays = (date: Date, days: number) =>
	new Date(date.setTime(date.getTime() - days * 24 * 60 * 60 * 1000));

export const notLaterThanToday = (date: Date) =>
	date.getTime() >= new Date().getTime();

export const toLocaleDate = (
	rawDate: Date | string | null | undefined,
): string => {
	if (!rawDate) {
		return '';
	}

	let date = rawDate;

	if (typeof rawDate == 'string') {
		date = new Date(rawDate);
	}

	return date.toLocaleString('ru-RU').replace(',', '');
};

export const fromLocaleDate = (
	rawDate: string | null | undefined,
): string | undefined => {
	if (!rawDate) {
		return undefined;
	}

	const [datePart, timePart] = rawDate.split(' ');

	const [dayPart, monthPart, yearPart] = datePart.split('.');

	let result = `${yearPart}-${monthPart}-${dayPart}`;

	if (timePart) {
		result += ` ${timePart}`;
	}

	return result;
};
