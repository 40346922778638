import {
	IApprovePossibleParams,
	IPossibleTransaction,
} from 'api/transactionGroup';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import { Button, Select, TextInput } from 'pay-kit';
import { useContext, useEffect, useState } from 'react';
import useGetWallets from 'utils/hooks/useGetWallets';

import styles from './ApproveConfirmationModal.module.scss';
import { validate } from './utils';

type ApproveConfirmationModalPropsType = {
	readonly confirmText: string;
	readonly onConfirm: (params: IApprovePossibleParams) => void;
	readonly onClose: () => void;
	readonly isLoading: boolean;
	readonly transaction?: IPossibleTransaction;
	readonly walletType?: string;
};

const ApproveConfirmationModal = ({
	confirmText,
	onConfirm,
	onClose,
	isLoading,
	transaction,
	walletType,
}: ApproveConfirmationModalPropsType) => {
	const [currency, setCurrency] = useState<string | undefined>();
	const [amount, setAmount] = useState<string | undefined>();
	const [walletId, setWalletId] = useState<string | undefined>();
	const [optionsForCurrency, setOptionsForCurrency] = useState<Array<any>>([]);
	const [errors, setErrors] = useState<{
		readonly amount?: string;
		readonly currency?: string;
		readonly walletType?: string;
	}>({});
	const getWalletsAPI = useGetWallets();
	const { walletTypes } = useContext(WalletTypesContext);
	const walletCurrencies =
		walletTypes.find((wallet) => wallet.code === transaction?.wallet_type)
			?.supported_currencies || [];
	const walletCurrenciesOptions: walletCurrenciesOptionsType =
		walletCurrencies.map((currency) => ({
			value: currency,
			label: currency,
		}));

	type walletCurrenciesOptionsType = readonly {
		readonly value: string;
		readonly label: string;
	}[];

	const { t } = useTranslation();

	useEffect(() => {
		if (!getWalletsAPI.isLoading && getWalletsAPI.list?.length) {
			setOptionsForCurrency(
				getWalletsAPI.list
					?.filter((el) => el.currency === currency)
					.map((w) => ({ label: w.identifier, value: w.hash_id })),
			);
		}
	}, [getWalletsAPI.isLoading]);

	useEffect(() => {
		getWalletsAPI.load({ type: walletType });
	}, []);

	useEffect(() => {
		if (transaction?.amount) {
			setAmount(transaction.amount ? transaction.amount.toString() : '0');
		}

		if (transaction?.currency_symbol) {
			setCurrency(transaction.currency_symbol);
		}
	}, [transaction]);

	useEffect(() => {
		if (walletCurrencies.length === 1) {
			setCurrency(walletCurrencies[0]);
		}
	}, [walletCurrencies]);

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();

				const syncValidationErrors = validate({ amount, currency });

				if (Object.keys(syncValidationErrors).length > 0) {
					setErrors({ ...syncValidationErrors });

					return;
				}

				if (
					currency === undefined ||
					amount === undefined ||
					transaction?.primary_id === undefined ||
					walletId === undefined
				) {
					return;
				}

				onConfirm({
					currency,
					amount: parseFloat(amount),
					primary_id: transaction?.primary_id,
					wallet_hash_id: walletId,
				});
			}}
			className={styles.ApproveConfirmationModal}
		>
			<TextInput
				name='amount'
				value={amount || ''}
				onChange={(e) => setAmount(e.target.value)}
				label={t('Amount')}
				data-test-id='ApproveConfirmationModal_amount'
				error={errors.amount}
			/>

			<Select
				name='currency'
				value={walletCurrenciesOptions.find((c) => c.value === currency)?.value}
				label={t('Currency')}
				onChange={(value) => setCurrency(value as string)}
				options={walletCurrenciesOptions}
				isDisabled={walletCurrencies.length === 1}
				data-test-id='ApproveConfirmationModal_currency'
				error={errors.currency}
			/>

			<Select
				name='wallet_id'
				value={walletId}
				label={t('Wallet number')}
				onChange={(value) => setWalletId(value as string)}
				options={optionsForCurrency}
				isDisabled={getWalletsAPI.list?.length === 0}
				data-test-id='ApproveConfirmationModal_wallet_id'
				error={errors.walletType}
				isLoading={getWalletsAPI.isLoading}
			/>

			<div className={styles.actions}>
				<Button
					data-test-id='lklN2pmIRgQExee8ME-lzV9'
					onClick={onClose}
					variant='secondary'
				>
					{t('Cancel')}{' '}
				</Button>
				<Button
					data-test-id='tkY7RI320VSKzKLvs-WF6bx'
					type='submit'
					disabled={isLoading}
					isLoading={isLoading}
					onClick={() => {}}
				>
					{confirmText}
				</Button>
			</div>
		</form>
	);
};

export default ApproveConfirmationModal;
