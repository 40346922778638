import { useTranslation } from 'pay-kit';
import useCreateWithdrawal from 'modules/TransferHistory/components/Withdrawal/hooks/useCreateWithdrawal';
import { useOperationHistoryContext } from 'modules/TransferHistory/contexts/OperationHistoryContext';
import { isRequired, isValidAmount } from 'modules/TransferHistory/helpers';
import { PayKitForm } from 'pay-kit';
import { ReactElement } from 'react';
import { useOperationHistoryTableContext } from 'modules/TransferHistory/contexts/OperationHistoryTableContext';

import styles from './withdrawal.module.scss';

const Withdrawal = () => {
	const createWithdrawalAPI = useCreateWithdrawal();
	const { onLoadOperationHistory } = useOperationHistoryTableContext();
	const { getWithdrawalsList, openWithdrawModal } =
		useOperationHistoryContext();

	const { t } = useTranslation();

	const onSubmit: OnSubmitHandler = (state) => {
		const onSuccess = () => {
			getWithdrawalsList();
			openWithdrawModal(false);

			window.pushAlert({
				title: t('Success'),
				type: `success`,
				description: t('Item has been successfully created'),
			});

			onLoadOperationHistory();
		};

		createWithdrawalAPI.create(
			{
				amount: state.amount,
				bitcoin_address: state.bitcoin_address,
			},
			onSuccess,
		);
	};

	const SCHEMA: any = [
		{
			type: 'Group',
			elements: [
				{
					type: 'TextInput',
					name: 'amount',
					// label: "Сумма списания с баланса сеттлемент",
					label: t('Withdrawal amount from balance'),
					className: styles.amountField,
					isRequired: true,
					validation: [isRequired, isValidAmount],
				},
				{
					type: 'TextInput',
					name: 'bitcoin_address',
					label: t('Write off address'),
					className: styles.addressField,
					isRequired: true,
					validation: [isRequired],
				},
			],
		},
		{
			type: 'Group',
			render: (element: ReactElement) => (
				<div className={styles.submitButton}>{element}</div>
			),
			elements: [
				{
					name: 'submit',
					type: 'SubmitButton',
					// label: "Вывести",
					isLoading: createWithdrawalAPI.isLoading,
					label: t('Withdraw'),
					onSubmit: onSubmit,
				},
			],
		},
	];
	const INIT_STATE = {
		amount: ``,
		bitcoin_address: ``,
	};

	return <PayKitForm.Builder schema={SCHEMA} initialState={INIT_STATE} />;
};

export default Withdrawal;

type OnSubmitHandler = (state: {
	readonly amount: string;
	readonly bitcoin_address: string;
}) => void;
