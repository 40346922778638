import { Tooltip } from '@paykassma/pay-kit';
import ReplaceIcon from 'assets/icons/copyReplace.svg';
import DismissCircle from 'assets/icons/dismissCircle.svg';
import ImportIcon from 'assets/icons/import.svg';
import { TrashIcon } from 'components/Icons/TrashIcon/TrashIcon';
import Hint from 'modules/AppSettings/Plugin/components/PSHints/components/Hint';
import Loader from 'modules/AppSettings/Plugin/components/PSHints/components/Loader';
import UploadFile from 'modules/AppSettings/Plugin/components/PSHints/components/UploadFile';
import {
	WalletType,
	useHintContext,
} from 'modules/AppSettings/Plugin/components/PSHints/context';
import useCreateImage from 'modules/AppSettings/Plugin/components/PSHints/hooks/useCreateImage';
import { Button, useTranslation } from 'pay-kit';
import {
	ChangeEventHandler,
	DragEventHandler,
	FC,
	MouseEventHandler,
	useReducer,
	useState,
} from 'react';

import styles from './fileControlPanel.module.scss';

const fileAcceptedTypes = [`gif`, `mp4`, `m4v`, `mpeg`, `mpg`];

const initState = {
	file: null,
	isGif: false,
	isFormatValid: true,
	fileSizeExceeded: false,
	path: ``,
	uploading: false,
	isDragOver: false,
	format: undefined,
	fileSaving: false,
};

const ACTION_TYPES = {
	UPLOADING: `UPLOADING`,
	FORMAT_INVALID: `FORMAT_INVALID`,
	FORMAT_VALID: `FORMAT_VALID`,
	FILE_SIZE_VALID: `FILE_SIZE_VALID`,
	REMOVE_FILE: `REMOVE_FILE`,
	FILE_SIZE_EXCEEDED: `FILE_SIZE_EXCEEDED`,
} as const;

const reducer: ReduceType = (state, action) => {
	switch (action.type) {
		case 'FORMAT_VALID':
			return {
				...state,
				format: action.payload?.format,
				isGif: action.payload?.isGif,
				isFormatValid: action.payload?.isFormatValid,
			};
		case `FORMAT_INVALID`:
			return {
				...state,
				format: action.payload?.format,
				isFormatValid: action.payload?.isFormatValid,
				file: action.payload?.file,
				path: action.payload?.path,
				uploading: action.payload.uploading,
			};
		case `FILE_SIZE_VALID`:
			return {
				...state,
				file: action.payload?.file,
				path: action.payload?.path,
				uploading: action.payload?.uploading,
			};
		case `UPLOADING`:
			return { ...state, uploading: action.payload?.uploading };
		case `REMOVE_FILE`:
			return {
				...state,
				file: action.payload.file,
				path: action.payload.path,
				fileSizeExceeded: action.payload.fileSizeExceeded,
			};
		case `FILE_SIZE_EXCEEDED`:
			return {
				...state,
				file: action.payload.file,
				path: action.payload.path,
				fileSizeExceeded: action.payload.fileSizeExceeded,
				uploading: action.payload.uploading,
			};
	}
};

const FileControlPanel: FC<FileControlPanelType> = ({ walletType }) => {
	const { t } = useTranslation();
	const hintContextAPI = useHintContext();
	const createImageAPI = useCreateImage();
	const [mouseOver, setMouseOver] = useState(false);

	const [state, dispatch] = useReducer(
		reducer,
		{
			...initState,
			isGif: walletType?.hint?.url.endsWith('gif'),
			path: walletType?.hint?.url,
		},
		undefined,
	);

	const uploadFile = (file: File) => {
		// setUploading(true);

		dispatch({ type: `UPLOADING`, payload: { uploading: true } });

		const reader = new FileReader();

		reader.readAsDataURL(file);

		const format = fileAcceptedTypes.find((format) =>
			file.type.endsWith(format),
		);

		if (format) {
			dispatch({
				type: `FORMAT_VALID`,
				payload: {
					format: file.type,
					isGif: format === `gif`,
					isFormatValid: true,
				},
			});
		} else {
			dispatch({
				type: `FORMAT_INVALID`,
				payload: {
					format: ``,
					isFormatValid: false,
					file: null,
					path: undefined,
					uploading: false,
				},
			});

			return;
		}

		reader.onload = () => {
			if (file.size < 20 * 1024 * 1024) {
				dispatch({
					type: `FILE_SIZE_VALID`,
					payload: {
						file: file,
						path: reader.result as string,
						uploading: false,
					},
				});
			} else {
				dispatch({
					type: `FILE_SIZE_EXCEEDED`,
					payload: {
						file: null,
						path: undefined,
						uploading: false,
						fileSizeExceeded: true,
					},
				});
			}
		};
	};

	const onUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
		const file = e.target.files && e.target.files[0];

		if (file) {
			uploadFile(file);
		}
	};

	const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();

		const file = e.dataTransfer.files && e.dataTransfer.files[0];

		uploadFile(file);
	};

	const inputFileField = (
		<input
			data-test-id='NN1hKNeOga-IyVyzJCX5Z'
			className={styles.hiddenFileInput}
			type='file'
			onChange={onUpload}
		/>
	);

	const uploadIcon =
		state.isFormatValid || state.fileSizeExceeded ? (
			<ImportIcon />
		) : (
			<DismissCircle />
		);

	const pathExists = state.path === walletType?.hint?.url;

	const onRemove = () => {
		if (pathExists) {
			hintContextAPI?.removeHint({
				name: walletType?.name,
				code: walletType?.code,
			});
		} else {
			dispatch({
				type: `REMOVE_FILE`,
				payload: {
					file: null,
					path: undefined,
					fileSizeExceeded: false,
				},
			});
		}
	};

	const onSave = () => {
		const onSuccess = () => {
			hintContextAPI?.uploadingFile(false);
			hintContextAPI?.closeHintModal();

			window.pushAlert({
				title: t('Success'),
				description: t('Media files have been successfully uploaded'),
				type: 'success',
			});
		};

		if (walletType?.code && state.file) {
			hintContextAPI?.uploadingFile(true);

			createImageAPI.create(
				{
					wallet_type: walletType.code,
					file: state.file,
				},
				() => {
					hintContextAPI?.loadImages(onSuccess);
				},
			);
		}
	};

	const onMouseOver: MouseEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();
		setMouseOver(true);
	};

	const onMouseLeave: MouseEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();
		setMouseOver(false);
	};

	const actionGroupButtonsStyle = hintContextAPI?.isFileUploading
		? [styles.actionGroupButtons, styles.pointerEventsNone].join(' ')
		: styles.actionGroupButtons;

	const trashBinStyle = hintContextAPI?.isFileUploading
		? [styles.trashBin, styles.pointerEventsNone].join(' ')
		: styles.trashBin;

	return (
		<div className={styles.fileControlPanel}>
			<div
				className={styles.dropZone}
				onDrop={onDrop}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
			>
				{state.path ? (
					<Loader loading={state.uploading}>
						<Hint
							url={state.path || walletType?.hint?.url}
							format={state.format}
							isGif={state.isGif}
						/>
					</Loader>
				) : (
					<Loader loading={state.uploading}>
						<UploadFile
							Icon={uploadIcon}
							invalidFormat={!state.isFormatValid}
							fileSizeExceeded={state.fileSizeExceeded}
							mouseOver={mouseOver}
						>
							{inputFileField}
						</UploadFile>
					</Loader>
				)}
			</div>
			<div className={styles.replaceFile}>
				{state.path && (
					<>
						<Tooltip tip={t('Change')} preferredSide='bottom'>
							<label className={actionGroupButtonsStyle}>
								<ReplaceIcon />
								{inputFileField}
							</label>
						</Tooltip>
						<Tooltip tip={t('Delete')} preferredSide='bottom'>
							<label
								data-test-id='biV4pA8UzCc6DYCHHaPMo'
								className={actionGroupButtonsStyle}
								onClick={onRemove}
							>
								<TrashIcon activeClass={trashBinStyle} />
							</label>
						</Tooltip>
					</>
				)}

				<Button
					data-test-id='9xeIzzJhkE-1U3gEsI-TI'
					classname={styles.save}
					onClick={onSave}
					isLoading={hintContextAPI?.isFileUploading}
					disabled={!state.path || state.path === walletType?.hint?.url}
				>
					{t('Save')}{' '}
				</Button>
			</div>
		</div>
	);
};

export default FileControlPanel;

type FileControlPanelType = { readonly walletType: WalletType | undefined };

type StateType = {
	readonly file: Blob | null;
	readonly isGif: boolean;
	readonly isFormatValid: boolean;
	readonly fileSizeExceeded: boolean;
	readonly path: string;
	readonly uploading: boolean;
	readonly isDragOver: boolean;
	readonly format: string;
};

type ActionType = {
	readonly type: keyof typeof ACTION_TYPES;
	readonly payload: Partial<StateType>;
};

type ReduceType = (
	state: Partial<StateType>,
	action: ActionType,
) => Partial<StateType>;
