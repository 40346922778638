import { errorsMap } from 'utils/enums';

const delay = (milliseconds: number) =>
	new Promise((resolve) => {
		setTimeout(resolve, milliseconds);
	});

const download = async (url: string) => {
	const a = document.createElement('a');

	a.href = url;
	a.style.display = 'none';
	document.body.append(a);
	a.click();
	// Chrome requires the timeout
	await delay(100);
	a.remove();
};

export const multiDownload = async (urls: readonly string[]) => {
	if (!urls) {
		throw new Error(errorsMap.urlsIsrequired);
	}

	for (const [index, url] of urls.entries()) {
		await delay(index * 1000);
		download(url);
	}
};
