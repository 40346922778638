import Filter from 'components/Filter';
import { Roles, useHasAccessHook } from 'contexts/AuthContext';
import constructQueryStringParams from 'helpers/constructQueryStringParams';
import { changeLogColumns } from 'modules/ChangeLog/columns';
import useChangeLogActionList from 'modules/ChangeLog/hooks/useChangeLogActionList';
import useChangeLogList from 'modules/ChangeLog/hooks/useChangeLogList';
import useChangeLogObjectList from 'modules/ChangeLog/hooks/useChangeLogObjectList';
import useChangeLogUsers from 'modules/ChangeLog/hooks/useChangeLogUsers';
import ReloadButton from 'modules/Postbacks/components/ReloadButton';
import { useTranslation } from 'pay-kit';
import { Table } from 'pay-kit';
import { useEffect, useState } from 'react';
import useURLState from 'utils/hooks/useURLState';
import { fromLocaleDate, notLaterThanToday } from 'utils/date';
import useURLFilters from 'utils/hooks/useURLFilters';

import styles from './changeLog.module.scss';

const ChangeLog = () => {
	const [filters, setFilters] = useURLFilters({
		dateRangeFields: ['date_range'],
	});
	const changeLogUsersAPI = useChangeLogUsers();
	const changeLogObjectAPI = useChangeLogObjectList();
	const changeLogAPI = useChangeLogList();
	const changeLogActionAPI = useChangeLogActionList();
	const [page, setCurrentPage] = useURLState<number | string>(
		'page',
		changeLogAPI.paginationInfo?.current_page || 1,
	);
	const [limit, setLimit] = useURLState<number>(
		'limit',
		changeLogAPI.paginationInfo?.per_page || 10,
	);
	const { t } = useTranslation();

	useHasAccessHook({ rule: Roles.VIEW_CHANGE_LOG, redirect: true });

	const OnGetChangeLogList = () => {
		const { date_range, ...rest } = filters;

		const rawParams = {
			...rest,
			date_from: fromLocaleDate(date_range?.from),
			date_to: fromLocaleDate(date_range?.to),
			page,
			limit,
		};

		if (date_range) {
			rawParams.date_from = fromLocaleDate(date_range?.from);
			rawParams.date_to = fromLocaleDate(date_range?.to);
		}

		const params = constructQueryStringParams(rawParams);

		changeLogAPI.load(params);
	};

	useEffect(OnGetChangeLogList, [filters, page, limit]);

	useEffect(() => {
		changeLogObjectAPI.load();
		changeLogUsersAPI.load();
	}, []);

	useEffect(() => {
		changeLogActionAPI.load(
			constructQueryStringParams({ object_type: filters?.object_type }),
		);
	}, [filters?.object_type]);

	const userNameOptions = changeLogUsersAPI.list.map(({ name }) => ({
		label: name,
		value: name,
	}));
	const objectTypeOptions = changeLogObjectAPI.list.map(({ name, id }) => ({
		label: name,
		value: id,
	}));
	const actionOptions = changeLogActionAPI.list.map(({ name, id }) => ({
		label: name,
		value: id,
	}));

	return (
		<div>
			<Filter
				classNames={{
					wrapper: styles.logsFiltersWrapper,
				}}
				values={filters}
				fields={[
					{
						name: 'username',
						className: styles.logsField,
						placeholder: t('User'),
						component: 'select',
						options: userNameOptions,
						isLoading: changeLogUsersAPI.isLoading,
					},
					{
						name: 'object_type',
						className: styles.logsField,
						placeholder: t('Object type'),
						component: 'select',
						options: objectTypeOptions,
						isLoading: changeLogObjectAPI.isLoading,
					},
					{
						name: 'object_id',
						className: styles.logsField,
						placeholder: t(`Object ID`),
						component: 'textInput',
					},
					{
						name: 'action',
						className: styles.logsField,
						placeholder: t('Action type'),
						component: 'select',
						options: actionOptions,
						isLoading: changeLogActionAPI.isLoading,
					},
					// TODO: date_range filter doesn't work. Probably it's backend problem.
					{
						name: 'date_range',
						component: 'dateRange',
						placeholder: t(`Date`),
						fromPlaceholder: t(`Date from`),
						toPlaceholder: t(`Date to`),
						dateFormat: 'DD.MM.YYYY',
						withTime: true,
						blockPredicate: notLaterThanToday,
						customStyles: () => ({
							right: 'unset',
							transform: 'translateX(-200px)',
						}),
						// withSeconds: false,
						// dateTimeFormat: "YYYY-MM-DD HH:mm",
					},
				]}
				onApply={(values) => {
					setCurrentPage(1);
					setFilters(values);
				}}
			/>

			<div className={styles.changeLogsTableWrapper}>
				<Table
					rowKey='uuid'
					className={styles.changeLogsTable}
					data={changeLogAPI.list}
					columns={changeLogColumns}
					isLoading={changeLogAPI.isLoading}
					skeleton={{ rowsCount: limit }}
					paginator={{
						currentPage: parseInt(`${page}`),
						setCurrentPage,
						limit,
						setLimit,
						lastAvailablePage:
							changeLogAPI.paginationInfo?.last_available_page || 1,
						prefixElement: (
							<ReloadButton
								data-test-id='PPy7FPwz5A06ApnarXH8_'
								isLoading={changeLogAPI.isLoading}
								onClick={OnGetChangeLogList}
							/>
						),
						className: styles.paginator,
						bottomPaginatorProps: {
							className: styles.bottomPaginator,
						},
					}}
				/>
			</div>
		</div>
	);
};

export default ChangeLog;

type FiltersType = {
	readonly username: string;
	readonly object_type: string;
	readonly object_id: string;
	readonly date_range: {
		readonly from: string;
		readonly to: string;
	};
};
