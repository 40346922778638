import { useTranslation } from '@paykassma/pay-kit';
import api, { NewCommissionType } from 'api/tariffsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

type useAddCommissionHookType = () => {
	readonly add: (
		data: NewCommissionType,
		args: {
			readonly onSuccess?: (res: any) => void;
			readonly onError?: (error: Error) => void;
		},
	) => void;
	readonly isLoading: boolean;
};

const useAddCommissionHook: useAddCommissionHookType = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const add: ReturnType<useAddCommissionHookType>['add'] = (
		data: NewCommissionType,
		{ onSuccess, onError },
	) => {
		setIsLoading(true);

		api
			.addCommission(data)
			.then((result) => {
				if (result.status !== 'fail') {
					onSuccess?.(result);

					window.pushAlert({
						type: 'success',
						description: t('Comission successfully added'),
						timeout: 2000,
					});
				} else {
					onError?.(result.message);

					Object.keys(result.message).forEach((key) => {
						const value = result.message[key];

						window.pushAlert({
							type: 'error',
							description: value.join('\n'),
							timeout: 2000,
						});
					});
				}

				return result;
			})
			.finally(() => setIsLoading(false))
			.catch((error) => {
				window.pushAlert({
					type: 'error',
					description: errorsMap.cantUpdate,
					timeout: 2000,
				});

				console.error(error);
			});
	};

	return {
		add,
		isLoading,
	};
};

export default useAddCommissionHook;
