import API from 'api';
import { GetImagesType } from 'api/walletTypesGroup';
import { useCallback, useState } from 'react';
import { errorsMap } from 'utils/enums';

const useGetImages = () => {
	const [data, setData] = useState<GetImagesType | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);

	const load: LoadType = useCallback((onSuccess) => {
		setIsLoading(true);
		setError(null);

		API.walletType
			.getImages()
			.then((res) => {
				if (res.status === 'success') {
					setData(res.data);
					onSuccess?.();
				} else {
					// throw new Error("Unexpected response in useGetPluginSettings");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	}, []);

	return {
		load,
		data,
		isLoading,
		error,
	};
};

export default useGetImages;

type LoadType = (onSuccess?: () => void) => void;
