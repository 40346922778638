import API from 'api';
import { ChangeLogActionType } from 'api/changeLogGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

const useChangeLogActionList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const [list, setList] = useState<readonly ChangeLogActionType[]>([]);

	const load = (params: string) => {
		setIsLoading(true);
		setError(null);

		API.changeLog
			.getChangeLogActionList(params)
			.then((res) => {
				if (res.status === 'ok') {
					setList(res.data);
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	};

	return {
		load,
		list,
		isLoading,
		error,
	};
};

export default useChangeLogActionList;
