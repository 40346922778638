import api, { ICurrenciesListItem } from 'api/walletTypesGroup';
import { CurrenciesContext } from 'contexts/CurrenciesContext';
import {
	Button,
	Loader,
	Modal,
	ScrollBox,
	TextInput,
	Toggler,
	useTranslation,
} from 'pay-kit';
import { useContext, useEffect, useState } from 'react';
import { errorsMap } from 'utils/enums';

import styles from './CurrenciesSettings.module.scss';

export interface ICurrenciesSettingsProps {
	readonly psId?: string;
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
}

const lCase = (str: string) => str.toLocaleLowerCase();

const CurrenciesSettings: React.FC<ICurrenciesSettingsProps> = ({
	psId,
	isOpen,
	onClose,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [currencies, setCurrenciesList] = useState<
		readonly ICurrenciesListItem[]
	>([]);
	const [newCurrencies, setNewCurrenciesList] = useState<readonly string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [currenciesListIsLoading, setCurrenciesListIsLoading] =
		useState<boolean>(false);

	const allCurrenciesContext = useContext(CurrenciesContext);
	const notListedCurrencies = allCurrenciesContext.list.filter(
		(c) =>
			currencies.find((currency) => currency.currency === c.code) === undefined,
	);

	const foundCurrencies =
		searchQuery === ''
			? currencies
			: currencies.filter((c) =>
					lCase(c.currency).includes(lCase(searchQuery)),
				);

	const foundNotListedCurrencies =
		searchQuery === ''
			? notListedCurrencies
			: notListedCurrencies.filter((c) =>
					lCase(c.code).includes(lCase(searchQuery)),
				);

	const allCurrenciesApplied =
		currencies.filter((c) => !c.is_applied).length === 0 &&
		newCurrencies.length === notListedCurrencies.length;

	const toggleAllCurrencies = (applyAll: boolean) => {
		if (applyAll) {
			setNewCurrenciesList(notListedCurrencies.map((c) => c.code));

			setCurrenciesList((prevList) =>
				prevList.map((c) => ({ ...c, is_applied: true })),
			);
		} else {
			setNewCurrenciesList([]);

			setCurrenciesList((prevList) =>
				prevList.map((c) => ({ ...c, is_applied: false })),
			);
		}
	};

	const loadCurrenciesList = () => {
		if (psId === undefined) {
			setCurrenciesList([]);

			return;
		}

		setCurrenciesListIsLoading(true);

		api
			.getCurrenciesList(psId)
			.then((res: any) => {
				if (res.status === 'success' && Array.isArray(res?.data?.items)) {
					setCurrenciesList(res?.data?.items);

					return res;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setCurrenciesListIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(loadCurrenciesList, [psId]);

	useEffect(() => {
		if (!isOpen) {
			setSearchQuery('');
		}
	}, [isOpen]);

	const onToggle = (currency: string, value: boolean) => {
		setCurrenciesList((prevValue) => {
			const currenciesListClone = JSON.parse(JSON.stringify(prevValue));

			return currenciesListClone.map((c: ICurrenciesListItem) => {
				return {
					...c,
					is_applied: c.currency === currency ? value : c.is_applied,
				};
			});
		});
	};

	const onUnlistedCurrencyToggle = (c: string, value: boolean) => {
		if (value) {
			setNewCurrenciesList((prevList) => [...prevList, c]);
		} else {
			setNewCurrenciesList((prevList) => prevList.filter((_c) => _c !== c));
		}
	};

	const onSubmit = () => {
		if (!psId) {
			return;
		}

		setIsLoading(true);

		api
			.updateCurrenciesList(psId, {
				currencies: newCurrencies,
				items: currencies,
			})
			.then((res) => {
				if (res.status === 'success') {
					onSuccess();

					window.pushAlert({
						type: 'success',
						description: t('Data updated successfully'),
					});

					return res;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((err) => {
				console.error(err);
			});
	};

	const modalContent = (
		<div className={styles.wrapper}>
			<h4>{t('Applied currencies')}</h4>

			<TextInput
				name='search'
				placeholder={t('Search')}
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				data-test-id='searchQuery'
			/>

			<ScrollBox className={styles.scrollBox}>
				<div className={styles.currencies}>
					{foundCurrencies.map(({ currency, id, is_applied }) => (
						<div key={id} className={styles.togglerWrapper}>
							<Toggler
								value={is_applied}
								label={currency}
								onChange={(v) => onToggle(currency, v)}
								data-test-id='currencyToggler'
							/>
						</div>
					))}

					{foundNotListedCurrencies.map((c) => (
						<div key={c.code} className={styles.togglerWrapper}>
							<Toggler
								value={newCurrencies.includes(c.code)}
								label={c.code}
								onChange={(v) => onUnlistedCurrencyToggle(c.code, v)}
								data-test-id='currencyToggler'
							/>
						</div>
					))}

					<div className={styles.togglerWrapper}>
						<Toggler
							value={allCurrenciesApplied}
							label={t('All')}
							onChange={toggleAllCurrencies}
							data-test-id='allCurrenciesToggler'
						/>
					</div>
				</div>
			</ScrollBox>

			<div className={styles.actions}>
				<Button
					onClick={onSubmit}
					isLoading={isLoading}
					disabled={isLoading || currenciesListIsLoading}
					data-test-id=''
				>
					{t('Save')}
				</Button>
			</div>
		</div>
	);

	return (
		<Modal
			isOpen={isOpen}
			title={t('Payment system currency')}
			onClose={onClose}
		>
			{currenciesListIsLoading || allCurrenciesContext.isLoading ? (
				<Loader />
			) : (
				modalContent
			)}
		</Modal>
	);
};

export default CurrenciesSettings;
