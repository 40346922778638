import { Table } from '@paykassma/pay-kit';
import { getListFilterParamsType } from 'api/postbackGroup';
import Filter from 'components/Filter';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import React, { useContext, useEffect, useState } from 'react';
import {
	fromLocaleDate,
	notLaterThanToday,
	subtractDays,
	toLocaleDate,
} from 'utils/date';
import { rejectSettlement } from 'utils/filterSettlement';
import useURLState from 'utils/hooks/useURLState';
import { Roles } from 'contexts/AuthContext';
import { useAuthContext } from 'contexts/AuthContext/AuthContext';
import useURLFilters from 'utils/hooks/useURLFilters';

import { acceptedTableColumns } from '../components/columns/Columns';
import ReloadButton from '../components/ReloadButton';
import usePostbacksListAPI from '../hooks/usePostbacksListAPI';
import PostbacksActionsProvider from '../PostbacksActionsProvider';

import styles from './AcceptedPostbacks.module.scss';

const AcceptedPostbacks: React.FC = () => {
	const { t } = useTranslation();
	const [page, setCurrentPage] = useURLState<number>('page', 1);
	const [limit, setLimit] = useURLState<number>('limit', 10);
	const [filter, setFilter] = useURLFilters({
		initialState: {
			date_from: toLocaleDate(subtractDays(new Date(), 7)).split(' ')[0],
			date_to: toLocaleDate(new Date()).split(' ')[0],
		},
	});

	const transformFilterValues = (values: getListFilterParamsType) => {
		const data = { ...values };
		const dateKeys: ReadonlyArray<keyof getListFilterParamsType> = [
			'date_from',
			'date_to',
		];

		dateKeys.forEach((key) => {
			if (key in data) {
				data[key] = fromLocaleDate(data[key]).split(' ')[0];
			}
		});

		return data;
	};

	const { postbacks, isLoading, error, paginationInfo, getPostbackList } =
		usePostbacksListAPI();
	const walletTypesAPI = useContext(WalletTypesContext);
	const { hasRole } = useAuthContext();
	const resendPostbackAccess = hasRole(Roles.POSTBACK_SENDING);

	const loadPostbacks = () =>
		getPostbackList({
			accepted: 1,
			limit,
			page,
			...transformFilterValues(filter),
		});

	useEffect(loadPostbacks, [limit, page, filter]);

	return (
		<PostbacksActionsProvider postbacks={postbacks} onSuccess={loadPostbacks}>
			<Filter
				values={filter}
				onApply={(values) => {
					setCurrentPage(1);
					setFilter(values);
				}}
				classNames={{
					wrapper: styles.filter,
					inlineFilter: styles.inlineFilter,
				}}
				fields={[
					{
						name: 'wallet_type',
						placeholder: t('Wallet type'),
						component: 'select',
						options: rejectSettlement(walletTypesAPI.walletTypes).map(
							(type) => ({
								value: type.code,
								label: type.name,
							}),
						),
						isLoading: walletTypesAPI.isLoading,
					},
					{
						name: 'label',
						placeholder: t('Label'),
						component: 'textInput',
					},
					{
						name: 'merchant_order_id',
						placeholder: t('Order ID'),
						component: 'textInput',
					},
					{
						name: 'direction',
						options: [
							{ value: 'ingoing', label: t('Ingoing') },
							{ value: 'outgoing', label: t('Outgoing') },
						],
						placeholder: t('Direction'),
						component: 'select',
					},
					{
						name: 'date_from',
						// placeholder: "Дата от",
						placeholder: t('Date from'),
						component: 'date',
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: notLaterThanToday,
					},
					{
						name: 'date_to',
						// placeholder: "Дата до",
						placeholder: t('Date to'),
						component: 'date',
						dateFormat: 'DD.MM.YYYY',
						blockPredicate: notLaterThanToday,
					},
				]}
			/>

			<Table
				className={styles.AcceptedPostbacksTable}
				columns={acceptedTableColumns({ resendPostbackAccess })}
				data={postbacks}
				rowKey='id'
				message={error ? { text: `${error}`, type: 'error' } : undefined}
				isLoading={isLoading}
				skeleton={{ rowsCount: limit }}
				stickyHead
				paginator={{
					currentPage: paginationInfo?.current_page || 1,
					setCurrentPage,
					limit,
					setLimit,
					lastAvailablePage: paginationInfo?.last_available_page || 1,
					prefixElement: (
						<ReloadButton
							data-test-id='5E18rG-_uK6b_KQvVAJ9H'
							isLoading={isLoading}
							onClick={loadPostbacks}
						/>
					),
					className: styles.paginator,
					bottomPaginatorProps: {
						className: styles.bottomPaginator,
					},
				}}
			/>
		</PostbacksActionsProvider>
	);
};

export default AcceptedPostbacks;
