import { TariffType } from 'api/tariffsGroup';

const numStringToFloat = (str: string | null) => {
	if (str === null || str === '') {
		return '';
	}

	return `${parseFloat(str)}`;
};

export const prepareInitialState = (commissions: TariffType['commissions']) =>
	commissions.map((c) => ({
		...c,
		min_amount: numStringToFloat(c.min_amount),
		max_amount: numStringToFloat(c.max_amount),
		commission: numStringToFloat(c.commission),
	}));

export const isRowInEditMode = (
	formState: any,
	currentValue: string,
	props: any,
) => !props.path?.startsWith(formState?.rowPathInEditMode);

export const transformToFloatString = (value: string) =>
	value.replace(/[^\d.]/g, '');
