import { PayKitForm } from '@paykassma/pay-kit';
import { WalletTypesContext } from 'contexts/WalletTypesContext';
import { useTranslation } from 'pay-kit';
import { useContext } from 'react';
import { fromLocaleDate, notLaterThanToday } from 'utils/date';
import { rejectSettlement } from 'utils/filterSettlement';

import { LabelStatisticsReportReqType } from '/api/reportsGroup';

import { ActionsContext } from '../../../../../ActionsProvider';

import styles from './LabelStatistics.module.scss';

const LabelStatistics = () => {
	const walletsContext = useContext(WalletTypesContext);
	const { createLabelStatisticsReportAPI } = useContext(ActionsContext);

	const { t } = useTranslation();

	// wallet types
	const walletTypesOptions = rejectSettlement(walletsContext.walletTypes).map(
		({ name, code }) => ({
			label: name,
			value: code,
		}),
	);

	const FORM_SCHEMA = [
		{
			type: 'BaseSettingsSection',
			elements: [
				{
					name: 'report_type',
					type: 'Hidden',
				},
				{
					name: 'wallet_type',
					label: t('Wallet type'),
					type: 'Select',
					placeholder: '',
					options: walletTypesOptions,
					isLoading: walletsContext.isLoading,
					isRequired: true,
					validation: (value: string, { wallet_type }: FormStateType) =>
						wallet_type === undefined ? t('Choose wallet type') : undefined,
				},
				{ name: 'label', label: t('Label'), type: 'TextInput' },
				{
					name: 'date',
					label: t('Period'),
					type: 'DateRangePicker',
					isRequired: true,
					fromPlaceholder: t('From'),
					toPlaceholder: t('To'),
					dateFormat: `DD.MM.YYYY`,
					blockPredicate: notLaterThanToday,
					withTime: true,
					validation: (value: string, { date }: FormStateType) =>
						date === undefined ? t('Choose period') : undefined,
					customStyles: () => ({
						right: `unset`,
						top: `unset`,
						transform: `translate(-150px, -150px)`,
					}),
				},
			],
		},
		{
			type: 'BottomSection',
			elements: [
				{
					name: 'file_format',
					label: t('Report format'),
					type: 'Switcher',
					options: [
						{ value: 'xlsx', label: 'xlsx' },
						{ value: 'csv', label: 'csv' },
					],
					className: styles.formatSwitcher,
				},
				{
					type: 'SubmitButton',
					name: 'submitButton',
					label: t('Create'),
					isLoading: createLabelStatisticsReportAPI.isLoading,
					onSubmit: (formState: FormStateType) =>
						createLabelStatisticsReportAPI.create(prepareFormData(formState)),
				},
			],
		},
	];

	const customElements = {
		BaseSettingsSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => (
					<div className={styles.baseSettings}>{children}</div>
				)}
			/>
		),
		BottomSection: (props: any) => (
			<PayKitForm.Group
				{...props}
				render={(children) => <div className={styles.actions}>{children}</div>}
			/>
		),
	};

	return (
		<div className={styles.form}>
			<PayKitForm.Builder<FormStateType>
				schema={FORM_SCHEMA}
				initialState={{ file_format: 'xlsx', report_type: 'label_statistics' }}
				customElements={customElements}
			/>
		</div>
	);
};

export default LabelStatistics;

type FormStateType = {
	readonly report_type: 'label_statistics';
	readonly wallet_type?: string;
	readonly label?: string;
	readonly date?: {
		readonly from?: string;
		readonly to?: string;
	};
	readonly file_format: 'xlsx' | 'csv';
};

type prepareFormDataType = (
	rawFormData: FormStateType,
) => LabelStatisticsReportReqType;

const prepareFormData: prepareFormDataType = ({
	report_type,
	wallet_type,
	label,
	date,
	file_format,
}) => ({
	report_type,
	wallet_type,
	label,
	file_format,
	date_from: fromLocaleDate(date?.from),
	date_to: fromLocaleDate(date?.to),
});
