import api, { UpdateTranslationHintDataType } from 'api/translationsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

type useUpdateHintHookType = (args: {
	readonly onSuccess?: (res: any) => void;
	readonly onError?: (error: Error) => void;
}) => {
	readonly update: (
		packageId: string,
		data: UpdateTranslationHintDataType,
	) => void;
	readonly isLoading: boolean;
};

const useUpdateHintHook: useUpdateHintHookType = ({ onSuccess, onError }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const update = (packageId: string, data: UpdateTranslationHintDataType) => {
		setIsLoading(true);

		api
			.updateHint(packageId, data)
			.then((result) => {
				if (result.status === 'success') {
					onSuccess?.(result);

					return result;
				} else {
					throw new Error(errorsMap.anyResponse);
				}
			})
			.finally(() => setIsLoading(false))
			.catch((error) => {
				console.error(error);
				onError?.(error);
			});
	};

	return {
		update,
		isLoading,
	};
};

export default useUpdateHintHook;
