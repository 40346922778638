const copyToClipboard = (
	text: string,
	onSuccess?: () => void,
	onError?: () => void,
) => {
	navigator.clipboard
		.writeText(text.toString())
		.then((res) => {
			onSuccess?.();

			return res;
		})
		.catch((err) => {
			onError?.();
			console.error(err);
		});
};

export default copyToClipboard;
