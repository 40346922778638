import { useOutsideClick } from 'pay-kit';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { MenuPageType } from '../index';

import styles from './MenuPageItem.module.scss';
import SubGroup from './SubGroup';

export type MenuPageItemProps = {
	readonly item: MenuPageType;
};

type isItemActiveType = (
	item: MenuPageType,
	currentPathname: string,
) => boolean;

const isItemActive: isItemActiveType = (item, currentPathname) => {
	if (item.link === currentPathname) {
		return true;
	}

	if (currentPathname !== '/' && currentPathname.substring(1) === item.link) {
		return true;
	}

	if (Array.isArray(item?.groups)) {
		for (const group of item.groups) {
			for (const element of group.elements) {
				if (currentPathname.substring(1) === element.link) {
					return true;
				}
			}
		}
	}

	return false;
};

const MenuPageItem = ({ item }: MenuPageItemProps) => {
	const [isOpen, setOpen] = useState(false);
	const location = useLocation();
	const { pathname } = location;
	const isActive = isItemActive(item, pathname);
	const selfRef = useRef(null);

	useEffect(() => setOpen(false), [location]);

	const handleOpen = () => {
		setOpen((prev) => !prev);
	};

	const handleClickOutside = () => {
		setOpen(false);
	};

	useOutsideClick(selfRef, handleClickOutside);

	const itemWithGroup = () => {
		return (
			<div className={styles.MenuPageItem} ref={selfRef} data-active={isActive}>
				<div
					data-test-id={item.link}
					className={styles.link}
					onClick={handleOpen}
				>
					{item?.icon && item.icon}
					{item.title}
				</div>
				{item?.groups && (
					<div className={styles.container} data-open={isOpen}>
						<SubGroup group={item.groups} />
					</div>
				)}
			</div>
		);
	};

	const singleItem = () => {
		return (
			<div className={styles.MenuPageItem} data-active={isActive}>
				<NavLink
					data-test-id={item.link}
					className={styles.link}
					to={item.link}
					onClick={handleOpen}
				>
					{item?.icon && item.icon}
					{item.title}
				</NavLink>
			</div>
		);
	};

	return <>{item?.groups ? itemWithGroup() : singleItem()}</>;
};

export default MenuPageItem;
