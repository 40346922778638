import API from 'api';
import { GetChangeLogListType } from 'api/changeLogGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

type ListType = GetChangeLogListType['change_logs']['data'];

type paginationInfoType = Omit<GetChangeLogListType['change_logs'], 'data'>;

const useChangeLogList = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<null | Error>(null);
	const [list, setList] = useState<ListType>([]);
	const [paginationInfo, setPaginationInfo] = useState<paginationInfoType>(
		{} as paginationInfoType,
	);

	const load = (params: string) => {
		setIsLoading(true);
		setError(null);
		setList([]);

		API.changeLog
			.getChangeLogList(params)
			.then((res) => {
				if (res.status === 'ok') {
					const { data, ...rest } = res.change_logs;

					setPaginationInfo(rest);
					setList(data);

					return res;
				}

				throw new Error(errorsMap.anyResponse);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => setIsLoading(false));
	};

	return {
		load,
		list,
		paginationInfo,
		isLoading,
		error,
	};
};

export default useChangeLogList;
