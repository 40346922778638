import { useSearchParams } from 'react-router-dom';

const parseString = <T>(value: any, defaultValue: T): T => {
	try {
		return value.toString();
	} catch (err) {
		return defaultValue as T;
	}
};

const parseBoolean = <T>(value: any, defaultValue: T): T => {
	const parsedValue = { true: true, false: false }[value as string];

	return (parsedValue === undefined ? defaultValue : parsedValue) as T;
};

const parseNumber = <T>(value: any, defaultValue: T): T => {
	const parsedValue = parseFloat(value);

	return (isNaN(parsedValue) ? defaultValue : parsedValue) as T;
};

function useURLState<T>(
	name: string,
	defaultValue: T,
): readonly [state: T, setValue: (value: T) => void] {
	const [searchParams, setSearchParams] = useSearchParams();
	const allSearchParams = Object.fromEntries(searchParams.entries());
	const dataType = typeof defaultValue;

	const setValue = (value: any) => {
		try {
			setSearchParams({ ...allSearchParams, [name]: value.toString() });
		} catch (err) {
			setSearchParams({ ...allSearchParams, [name]: defaultValue as string });

			console.error(
				`useURLState: can't set ${value} as value. Default value used: ${defaultValue}`,
			);
		}
	};

	switch (dataType) {
		case 'string':
			return [
				parseString<T>(allSearchParams[name], defaultValue),
				setValue,
			] as any;
		case 'boolean':
			return [
				parseBoolean<T>(allSearchParams[name], defaultValue),
				setValue,
			] as any;
		case 'number':
			return [
				parseNumber<T>(allSearchParams[name], defaultValue),
				setValue,
			] as any;
		default:
			return [defaultValue, setValue];
	}
}

export default useURLState;

/*
	Usage:

	const [val1, setVal1] = useURLState("queryParamNumber", 5);
	const [val2, setVal2] = useURLState("queryParamBoolean", false);
	const [val3, setVal3] = useURLState("queryParamString", "testValue");
*/
