import { t } from '@paykassma/pay-kit';

export type FilterErrors = {
	transaction_id: string | null;
};

export const validateFilters = (v: FilterErrors) => {
	const filtersErrors: FilterErrors = {
		transaction_id: null,
	};

	if (v.transaction_id) {
		if (v.transaction_id.length >= 1 && v.transaction_id.length <= 3) {
			filtersErrors.transaction_id = t('Enter more than 4 characters');
		}
	}

	return filtersErrors;
};

export const isFilterInvalid = (v: FilterErrors) => {
	return Object.values(v).some((value) => value !== null);
};
