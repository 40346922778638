import { useTranslation } from 'pay-kit';
import api, { WithdrawalsReportReqType } from 'api/reportsGroup';
import { useState } from 'react';
import { errorsMap } from 'utils/enums';

export type CreateWithdrawalsReportType = (args?: {
	readonly onSuccess?: (data: any) => void;
	readonly onError?: (err: Error) => void;
}) => {
	readonly create: (data: WithdrawalsReportReqType) => void;
	readonly isLoading: boolean;
};

const CreateWithdrawalsReport: CreateWithdrawalsReportType = (props) => {
	const [isLoading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation();

	const create = (data: WithdrawalsReportReqType) => {
		setLoading(true);

		api
			.createWithdrawalsReport(data)
			.then((res: any) => {
				if (res?.status === 'success' || res?.status === 'ok') {
					props?.onSuccess?.(res);

					window.pushAlert({
						title: t('The report has been sent to the queue for processing'),
						type: 'success',
					});
				} else {
					// throw new Error("CreateWithdrawalsReport – Unexpected response");
					throw new Error(errorsMap.anyResponse);
				}

				return res;
			})
			.finally(() => setLoading(false))
			.catch((err) => {
				props?.onError?.(err);
				console.error(err);

				window.pushAlert({
					title: t('An error occurred while creating report'),
					type: 'error',
				});
			});
	};

	return {
		create,
		isLoading,
	};
};

export default CreateWithdrawalsReport;
