import RenderIf from 'components/RenderIf';
import {
	Button,
	Loader,
	Modal,
	ScrollBox,
	Select,
	TextInput,
	TimePickerRange,
	Toggler,
	useTranslation,
} from 'pay-kit';
import { useEffect } from 'react';
import { errorsMap } from 'utils/enums';
import useTimezonesHook from 'utils/hooks/useTimezones';

import useFormStateHook from '../../hooks/useFormHook';

import styles from './CommonSettings.module.scss';
import useConstructorSettingsHook from './hooks/useConstructorSettings';
import useProxySettingsHook from './hooks/useProxySettings';
import useUpdateSettingsHook from './hooks/useUpdateSettings';
import useWorktimeSettingsHook from './hooks/useWorktimeSettings';
import {
	validateImpressionCounter,
	validateNoTransactionsPeriod,
} from './utils';

export interface ICommonSettingsProps {
	readonly psId?: string;
	readonly paymentType?: 'P2P' | 'API' | 'MANUAL';
	readonly isOpen: boolean;
	readonly onClose: () => void;
	readonly onSuccess: () => void;
}

export type FormStateType = {
	readonly begin: string;
	readonly end: string;
	readonly timezone: string;
	readonly all_time: boolean;
	readonly use_proxy: boolean;
	readonly allowed_update_requisites: boolean;
	readonly transactions_verification: boolean;
	readonly is_check_amount: boolean;
	readonly disable_wallet_visibility: boolean;
	readonly impression_counter: number;
	readonly no_transactions_period: number;
};

const CommonSettings: React.FC<ICommonSettingsProps> = ({
	isOpen,
	onClose,
	psId,
	paymentType,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const initialState = {
		all_time: true,
		timezone: 'Europe/Moscow',
		begin: '00:00:00',
		end: '23:59:59',
		use_proxy: true,
		allowed_update_requisites: true,
		transactions_verification: true,
		is_check_amount: true,
		no_transactions_period: 0,
		impression_counter: 0,
		disable_wallet_visibility: false,
	};

	const { formState, setFieldState } = useFormStateHook<FormStateType>({
		initialState,
	});

	const constructorSettingsHook = useConstructorSettingsHook();
	const proxySettingsHook = useProxySettingsHook();
	const worktimeSettingsHook = useWorktimeSettingsHook();
	const timezonesHook = useTimezonesHook();
	const updateSettingsHook = useUpdateSettingsHook({
		onSuccess: () => {
			onSuccess();
			onClose();
			window.pushAlert({ type: 'success', title: t('Settings updated') });
		},
		onError: () => {
			// window.pushAlert({ type: "error", title: "Произошла ошибка при сохранении настроек" });
			window.pushAlert({ type: 'error', title: errorsMap.cantSaveTheSettings });
		},
	});

	const constructorSettingsReady =
		constructorSettingsHook.data?.data.allowed_update_requisites !==
			undefined && !constructorSettingsHook.isLoading;
	const proxySettingsReady =
		proxySettingsHook.data?.use_proxy !== undefined &&
		!proxySettingsHook.isLoading;
	const worktimeSettingsReady =
		worktimeSettingsHook.data?.time !== undefined &&
		!worktimeSettingsHook.isLoading;

	const isLoading =
		constructorSettingsHook.isLoading ||
		proxySettingsHook.isLoading ||
		worktimeSettingsHook.isLoading;

	const showAdvancedTimeSettings = formState.all_time !== true;
	const transactionsVerificationIsOn =
		formState.transactions_verification === true;

	const noTransactionsPeriodError = transactionsVerificationIsOn
		? validateNoTransactionsPeriod(formState.no_transactions_period.toString())
		: undefined;

	const impressionCounterError = transactionsVerificationIsOn
		? validateImpressionCounter(formState.impression_counter.toString())
		: undefined;

	const hasErrors =
		noTransactionsPeriodError !== undefined ||
		impressionCounterError !== undefined;

	useEffect(() => {
		if (psId !== undefined) {
			constructorSettingsHook.load(psId);
			proxySettingsHook.load(psId);
			worktimeSettingsHook.load(psId);
			timezonesHook.load();
		}
	}, [psId]);

	useEffect(() => {
		if (
			constructorSettingsReady &&
			proxySettingsReady &&
			worktimeSettingsReady
		) {
			setFieldState(
				'allowed_update_requisites',
				constructorSettingsHook.data.data.allowed_update_requisites,
			);

			setFieldState('use_proxy', proxySettingsHook.data.use_proxy);
			setFieldState('begin', worktimeSettingsHook.data?.time.begin);
			setFieldState('end', worktimeSettingsHook.data?.time.end);
			setFieldState('timezone', worktimeSettingsHook.data?.time.timezone);
			setFieldState('all_time', worktimeSettingsHook.data?.time.all_time);

			setFieldState(
				'transactions_verification',
				constructorSettingsHook.data.data.transactions_verification,
			);

			setFieldState(
				'no_transactions_period',
				constructorSettingsHook.data.data.no_transactions_period,
			);

			setFieldState(
				'disable_wallet_visibility',
				constructorSettingsHook.data.data.disable_wallet_visibility,
			);

			setFieldState(
				'impression_counter',
				constructorSettingsHook.data.data.impression_counter,
			);

			setFieldState(
				'is_check_amount',
				constructorSettingsHook.data.data.is_check_amount,
			);
		}
	}, [constructorSettingsReady, proxySettingsReady, worktimeSettingsReady]);

	useEffect(() => {
		if (formState.all_time === true) {
			setFieldState('begin', '00:00:00');
			setFieldState('end', '23:59:59');
			setFieldState('timezone', 'Europe/Moscow');
		}
	}, [formState.all_time]);

	const timeFieldsSchema = [
		{
			name: 'all_time',
			title: t('Working time'),
			subtitle: t('24 hours a day'),
		},
	];

	const submitHandler = () => {
		if (hasErrors) {
			return;
		}

		const data = { ...formState };

		if (!transactionsVerificationIsOn) {
			delete data.no_transactions_period;
			delete data.impression_counter;
		}

		updateSettingsHook.update(psId as string, data);
	};

	if (isLoading) {
		return (
			<Modal isOpen={isOpen} title={t('Settings')} onClose={onClose}>
				<Loader />
			</Modal>
		);
	}

	return (
		<Modal isOpen={isOpen} title={t('Settings')} onClose={onClose}>
			<ScrollBox scrollDirection='vertical' style={{ maxHeight: 500 }}>
				{timeFieldsSchema.map(({ name, title, subtitle }) => (
					<div key={name} className={styles.toggler}>
						<h4>{title}</h4>
						{subtitle && <h5>{subtitle}</h5>}

						<Toggler
							value={formState[name as keyof FormStateType] as boolean}
							onChange={(v) => setFieldState(name, v)}
							data-test-id=''
							noError
						/>
					</div>
				))}

				<RenderIf visible={showAdvancedTimeSettings}>
					<div className={styles.advancedTimeSettings}>
						<div className={styles.timerange}>
							<h5>{t('Time range')}</h5>
							<TimePickerRange
								key={`${formState?.begin}-${formState?.end}`} // hack for TimePickerRange. TODO: make value prop in component
								label='' // TODO: make optional in UI-kit
								pickerSettings={{
									init: formState.begin,
									onChange: (time: string) => setFieldState('begin', time),
									invalid: false,
								}}
								secondPickerSettings={{
									init: formState.end,
									onChange: (time: string) => setFieldState('end', time),
									invalid: false,
								}}
							/>
						</div>

						<div className={styles.timezones}>
							<h5>{t('Timezone')}</h5>

							<Select
								name='timezone'
								value={formState.timezone}
								onChange={(v) => setFieldState('timezone', v)}
								options={timezonesHook.list.map((tz) => ({
									label: tz.name,
									value: tz.value,
								}))}
								data-test-id=''
								className={styles.select}
								isLoading={timezonesHook.isLoading}
							/>
						</div>
					</div>
				</RenderIf>

				<div className={styles.toggler}>
					<h4>{t('Proxy')}</h4>
					<h5>{t('Use proxy')}</h5>
					<Toggler
						value={formState.use_proxy as boolean}
						onChange={(v) => setFieldState('use_proxy', v)}
						data-test-id='use_proxy'
						noError
					/>
				</div>

				<RenderIf visible={paymentType === 'P2P'}>
					<div className={styles.toggler}>
						<h4>{t('Plugin')}</h4>
						<h5>{t('Allow requisite to be updated')}</h5>
						<Toggler
							value={formState.allowed_update_requisites as boolean}
							onChange={(v) => setFieldState('allowed_update_requisites', v)}
							data-test-id='allowed_update_requisites'
						/>
					</div>

					<div className={styles.toggler}>
						<h4>{t('Transactions')}</h4>
						<h5>{t('Checking the amount upon activation')}</h5>
						<Toggler
							value={formState.is_check_amount as boolean}
							onChange={(v) => setFieldState('is_check_amount', v)}
							data-test-id='is_check_amount'
						/>
					</div>

					<div className={styles.toggler}>
						<h5>{t('Checking for transactions')}</h5>
						<Toggler
							value={formState.transactions_verification as boolean}
							onChange={(v) => setFieldState('transactions_verification', v)}
							data-test-id='transactions_verification'
						/>
					</div>
				</RenderIf>

				<RenderIf visible={transactionsVerificationIsOn}>
					<TextInput
						label={t('No transaction period')}
						name='no_transactions_period'
						value={
							formState.no_transactions_period !== undefined
								? formState.no_transactions_period.toString()
								: ''
						}
						onChange={(e) =>
							setFieldState('no_transactions_period', e.target.value)
						}
						type='number'
						help={t(
							'The period of no transactions during which the check is considered unsuccessful (in minutes)',
						)}
						error={noTransactionsPeriodError}
						className={styles.textInput}
						data-test-id='no_transactions_period'
					/>

					<div className={styles.toggler}>
						<h5>{t('Disable wallet visibility')}</h5>
						<Toggler
							value={formState.disable_wallet_visibility as boolean}
							onChange={(v) => setFieldState('disable_wallet_visibility', v)}
							data-test-id='disable_wallet_visibility'
						/>
					</div>

					<TextInput
						label={t('Number of wallet impressions')}
						name='impression_counter'
						value={
							formState.impression_counter !== undefined
								? formState.impression_counter.toString()
								: ''
						}
						onChange={(e) =>
							setFieldState('impression_counter', e.target.value)
						}
						type='number'
						error={impressionCounterError}
						className={styles.textInput}
						data-test-id='impression_counter'
					/>
				</RenderIf>
			</ScrollBox>
			<div className={styles.actions}>
				<Button
					onClick={submitHandler}
					isLoading={updateSettingsHook.isLoading}
					disabled={hasErrors}
					data-test-id='submitButton'
				>
					{t('Save')}
				</Button>
			</div>
		</Modal>
	);
};

export default CommonSettings;
